import { CLIENT_ID, CLIENT_SECRET, DASHBOARD_URL, TRUSTED_URL } from '../constants';
import { TUserProfile } from '../redux/userSlice';
import { getAccessToken } from '../service/authService';
import { getAccessTokenNewTrusted } from '../service/newTrustedAuth';

type TokenResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

type ValidityResponse =
  | {
      active: boolean;
      exp: number;
      user_name: string;
      authorities: string[];
      clientId: string;
      scope: string[];
    }
  | {
      error: string;
      error_description: string;
    };

export const getTokensByCode = async (
  code: string,
  codeVerifierBase64: string,
): Promise<{ accessToken: string; refreshToken: string; expiresIn: number }> => {
  try {
    const body = [
      'grant_type=authorization_code',
      'redirect_uri=' + encodeURIComponent(DASHBOARD_URL + '/code'),
      'client_id=' + CLIENT_ID,
      'code=' + code,
      'code_verifier=' + codeVerifierBase64,
      'scope=userprofile',
    ].join('&');
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET));
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);
    const response = await fetch(TRUSTED_URL + '/idp/sso/oauth/token', {
      method: 'POST',
      headers,
      body,
    });

    const json = (await response.json()) as TokenResponse;
    const { access_token: accessToken, refresh_token: refreshToken, expires_in: expiresIn } = json;

    return { accessToken, refreshToken, expiresIn };
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getTokensByRefreshToken = async (
  refreshToken: string,
): Promise<{ accessToken: string; newRefreshToken: string; expiresIn: number }> => {
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refreshToken);
  const headers = new Headers();
  headers.set('Authorization', 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET));
  headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);

  try {
    const response = await fetch(TRUSTED_URL + '/idp/sso/oauth/token', {
      method: 'POST',
      headers,
      body: formData,
    });
    const json = (await response.json()) as TokenResponse;
    const {
      access_token: accessToken,
      refresh_token: newRefreshToken,
      expires_in: expiresIn,
    } = json;

    return { accessToken, newRefreshToken, expiresIn };
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const checkToken = async (accessToken: string): Promise<ValidityResponse> => {
  const formData = new FormData();
  formData.append('token', accessToken);

  const headers = new Headers();
  headers.set('Authorization', 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET));
  headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);
  try {
    const response = await fetch(TRUSTED_URL + '/idp/sso/oauth/check_token', {
      method: 'POST',
      headers,
      body: formData,
    });
    const json = (await response.json()) as ValidityResponse;
    return json;
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getRoleInApp = async (token?: string): Promise<any> => {
  try {
    const accessToken = token || (await getAccessToken());

    if (accessToken) {
      const headers = new Headers();
      headers.set('Authorization', 'Bearer ' + accessToken);
      headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);

      const response = await fetch(TRUSTED_URL + '/trustedapp/rest/groups/app/' + CLIENT_ID, {
        method: 'GET',
        headers,
      });

      const json = await response.json();

      return json.data;
    } else {
      return Promise.reject('No token');
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getProfile = async (): Promise<
  | {
      data: TUserProfile;
      success: boolean;
      user: TUserProfile;
    }
  | undefined
> => {
  try {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const headers = new Headers();
      headers.set('Authorization', 'Bearer ' + accessToken);
      headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);

      const response = await fetch(TRUSTED_URL + '/trustedapp/rest/user/profile/get', {
        method: 'GET',
        headers,
      });
      return response?.json();
    }
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getProfileNewTrusted = async (): Promise<Partial<TUserProfile> | undefined> => {
  try {
    const accessToken = await getAccessTokenNewTrusted();
    if (accessToken) {
      const headers = new Headers();
      headers.set('Authorization', 'Bearer ' + accessToken);
      headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);

      const response = await fetch(TRUSTED_URL + '/api/oidc/me', {
        headers,
      });
      const responseJson = await response?.json();
      const result: Partial<TUserProfile> = {};
      if (responseJson.name) result.displayName = responseJson.name;
      if (responseJson.email) result.email = responseJson.email;
      if (responseJson.family_name) result.familyName = responseJson.family_name;
      if (responseJson.phone) result.phone = responseJson.phone;
      result.id = responseJson.sub;
      return result;
    }
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getAvatar = async (thumbnailUrl: string): Promise<string | undefined> => {
  try {
    const accessToken = await getAccessToken();

    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + accessToken);
    headers.set('Access-Control-Allow-Origin', DASHBOARD_URL);

    const response = await fetch(TRUSTED_URL + '/trustedapp/rest/storage/' + thumbnailUrl, {
      method: 'GET',
      headers,
    });

    const blobUrl = URL.createObjectURL(await response.blob());

    return blobUrl;
  } catch (e) {
    return Promise.reject(e);
  }
};
