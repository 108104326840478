export interface IMessage<T> {
  code?: number;
  data: T;
}
export class ResponseError extends Error {
  code: number;
  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}

export interface ILicenseResponse {
  product_name: string;
  status: string;
  activated: string;
}
