import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useEffect } from 'react';
import { getAccessToken } from '../../service/authService';
import LinearProgress from '@material-ui/core/LinearProgress';

type UserRouteProps = RouteProps & {};

export const UserRoute: React.FC<UserRouteProps> = ({ children, ...rest }) => {
  const [wait, setWait] = React.useState(true);
  const [isAuthorized, setIsAuthorized] = React.useState(false);

  useEffect(() => {
    const getToken = async () => {
      try {
        setIsAuthorized(!!(await getAccessToken()));
      } catch (e) {
        setIsAuthorized(false);
      }
      setWait(false);
    };

    getToken();
  });

  return wait ? (
    <LinearProgress />
  ) : (
    <Route {...rest} render={() => (isAuthorized ? children : <Redirect to="/login" />)} />
  );
};
