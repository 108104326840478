import { GetAppSettingsDTO } from 'server/modules/general/dto/get-app-settings.dto';
import { BACKEND_URL } from '../constants';
import { IMessage } from '../interface';

export const fetchAppSettings = async (): Promise<IMessage<GetAppSettingsDTO> | undefined> => {
  try {
    const response = await fetch(BACKEND_URL + '/api/v1/general/init');
    const result = await response.json();

    return result;
  } catch (e) {
    console.log('fetchAppSettings error: ', e);
  }
};
