import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useEffect } from 'react';
import { getAccessTokenByRefreshToken, saveProfile, setUserRoles } from '../../service/authService';
import LinearProgress from '@material-ui/core/LinearProgress';
import Cookies from 'universal-cookie';
import { connect, useDispatch } from 'react-redux';
import { saveUserRole, TUserSlice } from '../../redux/userSlice';
import { RootState } from 'src/redux/rootReducer';

const cookies = new Cookies();

type ManagerRouteProps = RouteProps & { userRole: TUserSlice['userRole'] };

const mapStateToProps = (state: RootState) => ({
  userRole: state.user.userRole,
});

const ManagerRouteComponent: React.FC<ManagerRouteProps> = ({ children, userRole, ...rest }) => {
  const [wait, setWait] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = async () => {
      try {
        let token: string | undefined = cookies.get('accessToken');

        if (!token) {
          token = await getAccessTokenByRefreshToken(cookies.get('refreshToken'));
          if (token) {
            await setUserRoles(token, dispatch);
            await saveProfile(dispatch);
          }
        }
      } catch (e) {
        dispatch(saveUserRole(undefined));
      }
      setWait(false);
    };

    getToken();
  });

  return wait ? (
    <LinearProgress />
  ) : (
    <Route {...rest} render={() => (userRole === 'manager' ? children : <Redirect to="/main" />)} />
  );
};

export default connect(mapStateToProps)(ManagerRouteComponent);
