import { TRUSTED_URL, CLIENT_ID, CLIENT_SECRET, DASHBOARD_URL } from '../constants';
import {
  btoaRFC7636,
  getUrlParams,
  setCodeVerifier,
  sha256,
  stringToArrayBuffer,
} from '../service/newTrustedAuth';

export const sendAuthRequest = async (
  prompt: 'none' | 'consent' | 'select_account' = 'none',
): Promise<void> => {
  const codeVerifier = setCodeVerifier();
  const codeVerifierBase64 = btoaRFC7636(stringToArrayBuffer(codeVerifier));
  const codeChallenge = btoaRFC7636(await sha256(codeVerifierBase64));
  const url =
    encodeURI(TRUSTED_URL) +
    '/api/oidc/auth?client_id=' +
    encodeURIComponent(CLIENT_ID) +
    '&response_type=code&scope=openid email profile offline_access &redirect_uri=' +
    encodeURIComponent(DASHBOARD_URL + '/code') +
    '&code_challenge=' +
    encodeURIComponent(codeChallenge) +
    '&code_challenge_method=S256' +
    '&prompt=' +
    prompt;

  window.location.href = url;
};

export const getTokensByCode = async (): Promise<{
  accessToken: string;
  idToken: string;
  refreshToken: string;
  expiresIn: number;
}> => {
  const code = getUrlParams();
  const codeVerifier = window.localStorage.getItem('codeVerifier');
  const codeVerifierBase64 = codeVerifier ? btoaRFC7636(stringToArrayBuffer(codeVerifier)) : '';

  let accessToken = '';
  let idToken = '';
  let refreshToken = '';
  let expiresIn;

  try {
    const body =
      'code=' +
      encodeURIComponent(code) +
      '&code_verifier=' +
      encodeURIComponent(codeVerifierBase64) +
      '&client_id=' +
      encodeURIComponent(CLIENT_ID) +
      '&client_secret=' +
      encodeURIComponent(CLIENT_SECRET) +
      '&redirect_uri=' +
      encodeURI(DASHBOARD_URL + '/code') +
      '&grant_type=authorization_code';

    const response = await fetch(TRUSTED_URL + '/api/oidc/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });

    ({
      access_token: accessToken,
      id_token: idToken,
      refresh_token: refreshToken,
      expires_in: expiresIn,
    } = await response?.json());
  } catch (e) {
    console.log('getTokenByCode error: ', e);
  }

  return { accessToken, idToken, refreshToken, expiresIn };
};

export const getTokensByRefreshToken = async (
  refreshToken: string,
): Promise<{ accessToken: string; idToken: string; expiresIn: number }> => {
  const body =
    'grant_type=refresh_token' +
    '&refresh_token=' +
    encodeURIComponent(refreshToken) +
    '&client_id=' +
    encodeURIComponent(CLIENT_ID) +
    '&client_secret=' +
    encodeURIComponent(CLIENT_SECRET);

  let accessToken = '';
  let idToken = '';
  let expiresIn;
  try {
    const response = await fetch(TRUSTED_URL + '/api/oidc/token', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded ',
      },
      method: 'POST',
      body,
    });

    ({
      access_token: accessToken,
      id_token: idToken,
      expires_in: expiresIn,
    } = await response?.json());
  } catch (e) {
    console.log('getTokensByRefreshToken error: ' + e);
  }

  return { accessToken, idToken, expiresIn };
};

export const checkAccessToken = async (token: string): Promise<boolean> => {
  let active;

  try {
    const body = 'token=' + token;
    const encodedAppCredentials = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    const response = await fetch(TRUSTED_URL + '/api/oidc/token/introspection', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + encodedAppCredentials,
      },
      body,
    });

    ({ active } = await response.json());
  } catch (e) {
    console.log('checkAccessToken error: ' + e);
  }

  return !!active;
};

export const sendLogoutRequest = () => {
  window.location.href =
    TRUSTED_URL +
    '/api/oidc/session/end?post_logout_redirect_uri=' +
    DASHBOARD_URL +
    '&client_id=' +
    CLIENT_ID;
};
