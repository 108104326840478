//ie 11.x uses msCrypto
declare global {
  interface Window {
    msCrypto: Crypto;
  }
}

export const stringToArrayBuffer = (str: string): ArrayBuffer => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

export const sha256 = async (str: string): Promise<ArrayBuffer> => {
  const buffer = stringToArrayBuffer(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  return hashBuffer;
};

export const btoaRFC7636 = (buf: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buf);
  const length = bytes.byteLength;
  for (let i = 0; i < length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

export const randomString = (): string => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let str = '';
  const randomValues = Array.from(getCrypto().getRandomValues(new Uint8Array(43)));
  randomValues.forEach((v) => (str += charset[v % charset.length]));
  return str;
};

export const setCodeVerifier = (): string => {
  const codeVerifier = randomString();
  window.localStorage.setItem('codeVerifier', codeVerifier);
  return codeVerifier;
};

export const getCrypto = (): Crypto => {
  return window.crypto || window.msCrypto;
};

export const getCode = (): string | null => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  return url.searchParams.get('code');
};
