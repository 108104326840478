import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getAccessToken, saveProfile, setAccessToken, setUserRoles } from '../service/authService';

export const AuthByCode: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const getTokensAndUserInfo = async () => {
      try {
        await setAccessToken();
        const token = await getAccessToken();
        token && (await setUserRoles(token, dispatch));
        await saveProfile(dispatch);

        history.push('/');
      } catch (e) {
        console.log(e);
      }
    };

    getTokensAndUserInfo();
  });

  return <LinearProgress />;
};
