import Box from '@material-ui/core/Box';
import React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/rootReducer';
import { fetchApplications } from '../requests/applications';
import { CircularProgress } from '@material-ui/core';
import { saveUserApps, TApplication, TUserSlice } from '../redux/userSlice';
import { Application } from './Application';

const mapStateToProps = (state: RootState) => ({
  applications: state.user.applications,
});

type ApplicationComponentProps = {
  applications: TUserSlice['applications'];
};

const ApllicationsComponent: React.FC<ApplicationComponentProps> = ({ applications }) => {
  const dispatch = useDispatch();
  const [wait, setWait] = React.useState<boolean>(true);

  useEffect(() => {
    const getApps = async () => {
      if (!applications?.data?.length && !applications?.isFetching) {
        dispatch(saveUserApps({ isFetching: true, data: [] }));
        const data = await fetchApplications();
        dispatch(saveUserApps({ isFetching: false, data }));
      }
      setWait(false);
    };

    getApps();
  }, [applications?.data?.length, applications?.isFetching, dispatch]);

  return (
    <Box padding={6}>
      {wait ? (
        <CircularProgress />
      ) : (
        applications?.data?.map((app: TApplication) => {
          return <Application app={app} key={app.entityId} />;
        })
      )}
    </Box>
  );
};

export const ApplicationsList = connect(mapStateToProps)(ApllicationsComponent);
