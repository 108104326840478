import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useAppStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'calc(100vh - 64px)',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);
