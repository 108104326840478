import Cookies, { CookieSetOptions } from 'universal-cookie';
import { CLIENT_ID, DASHBOARD_URL, TRUSTED_URL } from '../constants';
import {
  getAvatar,
  getProfile,
  getRoleInApp,
  getTokensByCode,
  getTokensByRefreshToken,
} from '../requests/trusted';
import {
  btoaRFC7636,
  getCode,
  setCodeVerifier,
  sha256,
  stringToArrayBuffer,
} from '../utils/authUtils';
import { Role } from 'server/modules/network/network.interfaces';
import { saveUserAvatar, saveUserProfile, saveUserRole } from '../redux/userSlice';

const ACCESS_TOKEN_EXPIRATION_SEC = 900;
const REFRESH_TOKEN_EXPIRATION_SEC = 3600;
const cookies = new Cookies();

export const showWidget = async (): Promise<void> => {
  const codeVerifier = setCodeVerifier();
  const codeVerifierBase64 = btoaRFC7636(stringToArrayBuffer(codeVerifier));
  const codeChallenge = btoaRFC7636(await sha256(codeVerifierBase64));
  const url =
    TRUSTED_URL +
    'idp/sso/oauth' +
    '?client_id=' +
    CLIENT_ID +
    '&redirect_uri=' +
    encodeURIComponent(DASHBOARD_URL + '/code') +
    '&scope=' +
    'userprofile' +
    '&code_challenge=' +
    codeChallenge +
    '&code_challenge_method=' +
    'S256';

  window.location.href = url;
};

export const setAccessToken = async (): Promise<void> => {
  try {
    const codeVerifier = window.localStorage.getItem('codeVerifier');
    const codeVerifierBase64 = codeVerifier ? btoaRFC7636(stringToArrayBuffer(codeVerifier)) : '';

    const code = getCode();

    if (!code || !codeVerifier || !codeVerifierBase64) {
      console.error('No code');
      window.location.href = '/';
      return;
    }

    const { accessToken, refreshToken, expiresIn } = await getTokensByCode(
      code,
      codeVerifierBase64,
    );

    setTokenCookies(accessToken, refreshToken, expiresIn);
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getAccessToken = async (): Promise<string | void> => {
  const accessToken = cookies.get('accessToken');
  const refreshToken = cookies.get('refreshToken');

  if (accessToken) return accessToken;
  if (refreshToken) {
    return await getAccessTokenByRefreshToken(refreshToken);
  }
  return Promise.reject(new Error('No token'));
};

export const removeTokenCookies = (): void => {
  cookies.remove('accessToken');
  cookies.remove('refreshToken');
};

export const getAccessTokenByRefreshToken = async (
  refreshToken: string,
): Promise<string | undefined> => {
  const { accessToken, newRefreshToken, expiresIn } = await getTokensByRefreshToken(refreshToken);
  setTokenCookies(accessToken, newRefreshToken, expiresIn);

  return accessToken;
};

export const setUserRoles = async (accessToken: string, dispatch: Function) => {
  const roles = await getRoleInApp(accessToken);
  dispatch(
    saveUserRole(
      roles?.find((role: Role) => role.role === 'ADMIN' && role.status === 'ACTIVE')
        ? 'manager'
        : roles?.find((role: Role) => role.status === 'ACTIVE')
        ? 'user'
        : undefined,
    ),
  );
};

export const saveProfile = async (dispatch: Function) => {
  try {
    const response = await getProfile();
    if (response?.data) {
      const thumbnailUrl = response.data.properties.find((prop) => prop.type === 'thumbnailUrl');
      if (thumbnailUrl) {
        const avatar = await getAvatar(thumbnailUrl.value);
        avatar && dispatch(saveUserAvatar(avatar));
      }
      dispatch(saveUserProfile(response?.data));
    }
  } catch (e) {
    console.log(e);
  }
};

const setTokenCookies = (accessToken: string, refreshToken: string, expiresIn: number): void => {
  accessToken && refreshToken && removeTokenCookies();

  const cookieAttributes: CookieSetOptions = {
    // secure: true,
    //httpOnly: true,
    sameSite: 'strict',
    path: '/',
  };

  const accessTokenExpiration = new Date();
  accessTokenExpiration.setSeconds(
    accessTokenExpiration.getSeconds() + expiresIn || ACCESS_TOKEN_EXPIRATION_SEC,
  );

  accessToken &&
    cookies.set('accessToken', accessToken, {
      ...cookieAttributes,
      expires: accessTokenExpiration,
    });

  const refreshTokenExpiration = new Date();
  refreshTokenExpiration.setSeconds(
    refreshTokenExpiration.getSeconds() + REFRESH_TOKEN_EXPIRATION_SEC,
  );

  refreshToken &&
    cookies.set('refreshToken', refreshToken, {
      ...cookieAttributes,
      expires: refreshTokenExpiration,
    });
};
