import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import createTheme from '@material-ui/core/styles/createTheme';

export const appTheme = createTheme({
  palette: {
    primary: {
      light: blue[50],
      main: blue[500],
      dark: blue[900],
    },
    secondary: {
      light: grey[50],
      main: grey[500],
      dark: grey[900],
    },
  },
});
