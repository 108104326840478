import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(immutableCheck: false, serializableCheck: false),
});

export type AppDispatch = typeof store.dispatch;

export default store;