import React from 'react';
import Box from '@material-ui/core/Box';
import { useMainStyles } from './Main.styles';
// import { Link } from 'react-router-dom';
// import { CODE_CHECK, CODE_GENERATE, LICENSE_CHECK } from '../constants';


export const Main: React.FC<{}> = () => {
  const styles = useMainStyles();

  return (
    <Box className={styles.root}>
      {/* <Link className={styles.text} to={CODE_GENERATE}>
        Генерация активационного кода
      </Link>
      <Link className={styles.text} to={CODE_CHECK}>
        Проверка активационного кода
      </Link>
      <Link className={styles.text} to={LICENSE_CHECK}>
        Проверка лицензии
      </Link> */}
    </Box>
  );
};
