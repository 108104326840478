import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { useCheckLicenseStyles } from './CheckLicense.styles';
import { getLicenseInfo } from '../requests/license';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { IMessage } from '../interface';
import { errorToMessage } from '../utils/errorUtil';
import { LicenseDTO } from 'server/modules/license/dto/license.dto';
import { convertLicenseType } from '../helpers';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CheckLicense: React.FC<{}> = () => {
  const styles = useCheckLicenseStyles();
  const [toast, setToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [licenseInfo, setlicenseInfo] = useState<Partial<LicenseDTO>>();

  const handleCheckLicenseClick = async () => {
    setlicenseInfo({});
    try {
      const response: IMessage<LicenseDTO> = await getLicenseInfo(license);
      setlicenseInfo(response?.data);
    } catch (e) {
      if (e instanceof Error) {
        const message = errorToMessage(e);
        setToastMessage(message);
        setToast(true);
      }
    }
  };

  const licenseStatus = () => {
    switch (licenseInfo?.is_active) {
      case true:
        return 'Активирована';
      case false:
        return 'Не активирована';
      default:
        return '';
    }
  };

  const handleClose = () => {
    setToast(false);
  };

  return (
    <>
      <Container className={styles.root}>
        <Typography className={styles.text}>Проверка лицензионного ключа</Typography>
        <FormControl component="fieldset" className={styles.form}>
          <TextField
            required
            id="license"
            label="Введите лицензию"
            placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
            className={styles.input}
            size="medium"
            value={license}
            onChange={(e) => typeof e?.target?.value === 'string' && setLicense(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disableElevation
            disableFocusRipple
            className={styles.button}
            endIcon={<SendIcon />}
            onClick={handleCheckLicenseClick}
          >
            Проверить
          </Button>
        </FormControl>
        <div className={styles.responseOutput}>
          <Typography className={styles.label}>Продукт</Typography>
          <Typography className={styles.data}>{licenseInfo?.product?.product_name}</Typography>
          <Typography className={styles.label}>Тип лицензии</Typography>
          <Typography className={styles.data}>
            {licenseInfo?.license_type ? convertLicenseType(licenseInfo.license_type) : ''}
          </Typography>
          <Typography className={styles.label}>Статус ключа</Typography>
          <Typography className={styles.data}>{licenseStatus()}</Typography>
          <Typography className={styles.label}>Дата активации</Typography>
          <Typography className={styles.data}>{licenseInfo?.activated_at}</Typography>
        </div>
        <Snackbar open={toast} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {toastMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};
