import { GetCodeInfoDTO } from 'server/modules/code/dto/get-code-info.dto';

type TConvertedParams = {
  status?: string;
  account_type?: string;
  product_name?: string;
  license_param?: string;
};

export type GetCodeInfoDTOConverted = Omit<
  GetCodeInfoDTO,
  'status' | 'account_type' | 'product_name'
> &
  TConvertedParams;

//TODO: make imported enums work
// export const convertCodeInfo = (
//   data: GetCodeInfoDTO,
// ): Omit<GetCodeInfoDTO, 'status' | 'account_type' | 'product_name'> & TConvertedParams => {
//   return {
//     ...data,
//     ...{ status: CodeStatus[data.status] },
//     ...{ account_type: AccountType[data.account_type] },
//     ...{ product_name: ProductName[data.product_name] },
//   };
// };

export const convertCodeInfo = (data: GetCodeInfoDTO): GetCodeInfoDTOConverted => {
  let convertedParams: TConvertedParams = {};
  switch (data.status) {
    case 'active':
      convertedParams.status = 'Активирован';
      break;
    case 'blocked':
      convertedParams.status = 'Заблокирован';
      break;
    case 'inactive':
      convertedParams.status = 'Не активирован';
      break;
    default:
      break;
  }

  switch (data.account_type) {
    case 'partner':
      convertedParams.account_type = 'Пользовательский';
      break;
    case 'subscription':
      convertedParams.account_type = 'Партнерский';
      break;
    default:
      break;
  }

  switch (data.product_name) {
    case 'cryptoarm':
      convertedParams.product_name = 'КриптоАРМ';
      break;
    default:
      break;
  }

  switch (data.license_param) {
    case '1year':
      convertedParams.license_param = '1 год';
      break;
    default:
      break;
  }

  return {
    ...data,
    ...convertedParams,
  };
};
