import { combineReducers } from '@reduxjs/toolkit';
import appSettingsSlice from './appSettingsSlice';
import userSlice from './userSlice';

const rootReducer = combineReducers({
  user: userSlice,
  appSettings: appSettingsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
