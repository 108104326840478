import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import './App.css';
import { AppTopBar } from './components/AppTopBar';
import { Main } from './components/Main';
import { GenerateKey } from './components/GenerateKey';
import ManagerRoute from './components/routes/ManagerRoute';
import { APPLICATIONS, AUTH_SCHEME, CODE_CHECK, CODE_GENERATE, LICENSE_CHECK } from './constants';
import { connect, useDispatch } from 'react-redux';
import { RootState } from './redux/rootReducer';
import { CheckKey } from './components/CheckKey';
import { appTheme } from './components/Theme';
import Box from '@material-ui/core/Box';
import { useAppStyles } from './App.styles';
import { CheckLicense } from './components/CheckLicense';
import { UserRoute } from './components/routes/UserRoute';
import { AuthByCode } from './components/AuthByCode';
import { ApplicationsList } from './components/ApplicationsList';
import { fetchAppSettings } from './requests/general';
import { saveProducts } from './redux/appSettingsSlice';
import { AuthByNewTrusted } from './components/AuthByNewTrusted';
import { getAccessTokenNewTrusted, saveProfileNewTrusted } from './service/newTrustedAuth';
import { checkAccessToken } from './requests/oidc';
import { getAccessToken, saveProfile, setUserRoles } from './service/authService';
import NewManagerRoute from './components/routes/NewManagerRoute';
import { Login } from './components/Login';

const mapStateToProps = (state: RootState) => ({});

const App: React.FC<{}> = () => {
  const theme = appTheme;
  const styles = useAppStyles();
  const dispatch = useDispatch();
  const getAppSettings = async () => {
    const appSettings = await fetchAppSettings();
    if (appSettings?.data) {
      const products = appSettings.data.products;
      dispatch(saveProducts({ products }));
    }
  };
  getAppSettings();

  useEffect(() => {
    const getToken = async () => {
      try {
        if (AUTH_SCHEME === 'old') {
          const token = await getAccessToken();
          if (token) {
            await setUserRoles(token, dispatch);
            await saveProfile(dispatch);
          }
        } else {
          const token = await getAccessTokenNewTrusted();
          if (token) {
            const checkResult = await checkAccessToken(token);
            if (checkResult) {
              saveProfileNewTrusted(dispatch);
            }
          }
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }
    };
    getToken();
  }, [dispatch]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppTopBar />
          <Box className={styles.root}>
            {AUTH_SCHEME === 'new' ? (
              <Switch>
                <Route path="/code" children={<AuthByNewTrusted />} />
                {/* <NewUserRoute path={APPLICATIONS} children={<ApplicationsList />} /> */}
                <Route path="/main" children={<Main />} />
                <Route path={CODE_CHECK} children={<CheckKey />} />
                <Route path={LICENSE_CHECK} children={<CheckLicense />} />
                <NewManagerRoute path={CODE_GENERATE} children={<GenerateKey />} />
                <Redirect from="/" to="/main" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/login" children={<Login />} />
                <Route path="/code" children={<AuthByCode />} />
                <UserRoute path={APPLICATIONS} children={<ApplicationsList />} />
                <UserRoute path="/main" children={<Main />} />
                <ManagerRoute path={CODE_GENERATE} children={<GenerateKey />} />
                <ManagerRoute path={CODE_CHECK} children={<CheckKey />} />
                <ManagerRoute path={LICENSE_CHECK} children={<CheckLicense />} />
                <Redirect from="/" to="/main" />
              </Switch>
            )}
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
};

export default connect(mapStateToProps)(App);
