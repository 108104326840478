import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { useAppTopBarStyles } from './AppTopBar.styles';
import { RootState } from '../redux/rootReducer';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { APPLICATIONS, AUTH_SCHEME, CODE_CHECK, CODE_GENERATE, LICENSE_CHECK } from '../constants';
import { removeTokenCookies } from '../service/authService';
import { TUserSlice } from '../redux/userSlice';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sendLogoutRequest } from '../requests/oidc';
import { handleLoginClick, isManager } from '../helpers';

const mapStateToProps = (state: RootState) => ({
  userAvatar: state.user.userAvatar,
  userRole: state.user.userRole,
});

type TAppTopBarComponent = {
  userAvatar: TUserSlice['userAvatar'];
  userRole: TUserSlice['userRole'];
};

const AppTopBarComponent: React.FC<TAppTopBarComponent> = ({ userAvatar, userRole }) => {
  const styles = useAppTopBarStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRouteClick = (route: string) => {
    history.push(route);
  };

  const handleLogoClick = () => {
    history.push('/main');
  };

  const logout = () => {
    removeTokenCookies();
    if (AUTH_SCHEME === 'new') sendLogoutRequest();
    else window.location.href = '/';
  };

  return (
    <div className={styles.root}>
      <AppBar position="static" className={styles.appBar}>
        <Toolbar>
          <Typography component={'div'} onClick={handleLogoClick} className={styles.title}>
            Trusted License Service
          </Typography>
        </Toolbar>
        <Box className={styles.box}>
          {isManager(userRole) && (
            <Button
              disableRipple
              className={styles.button}
              onClick={() => handleChangeRouteClick(CODE_GENERATE)}
            >
              Генерация лицензии
            </Button>
          )}
          <Button
            disableRipple
            className={styles.button}
            onClick={() => handleChangeRouteClick(CODE_CHECK)}
          >
            Проверка ключа
          </Button>
          <Button
            disableRipple
            className={styles.button}
            onClick={() => handleChangeRouteClick(LICENSE_CHECK)}
          >
            Проверка лицензии
          </Button>
          {userRole ? (
            <Avatar className={styles.avatar} src={userAvatar} onClick={handleAvatarClick} />
          ) : (
            <Button variant="contained" onClick={handleLoginClick}>
              Вход
            </Button>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleClose}
            className={styles.menu}
          >
            <MenuItem onClick={handleClose}>
              <Button disableRipple className={styles.logoutButton} onClick={logout}>
                Выйти
              </Button>
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
    </div>
  );
};

export const AppTopBar = connect(mapStateToProps)(AppTopBarComponent);
