import { ResponseError } from '../interface';

export function errorToMessage(e: ResponseError | Error) {
  let message = '';
  if (e instanceof ResponseError) {
    if (e?.code === 404) message = 'Указанная сущность не найдена';
    if (e?.code === 403) message = 'Недостаточно прав';
    if (e?.code === 400) message = 'Неправильный запрос';
    if (e?.code === 500) message = "Внутрення ошибка сервера";
  }
  if (e.message === 'Failed to fetch') message = 'Не удалось выполнить запрос';
  return message;
}
