import { getAccessToken } from '../service/authService';
import { BACKEND_URL, TRUSTED_URL } from '../constants';
import { request } from '../utils/requestUtil';
import { IMessage } from '../interface';
import { CodeToAppDTO } from 'server/modules/application/dto/connect-code-to-app.dto';
import { GetAppInfoDTO } from 'server/modules/application/dto/get-app-info.dto';
import { TApplication } from 'src/redux/userSlice';

export const fetchApplications = async (): Promise<TApplication[]> => {
  const accessToken = await getAccessToken();

  const response: any = await fetch(TRUSTED_URL + '/trustedapp/rest/app/list/v2/?type=4', {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + accessToken },
  });
  const applications = await response.json();

  let formattedApplications: TApplication[] = [];
  formattedApplications = applications?.data?.filter(
    (application: TApplication) =>
      application.type === 0 &&
      application.groups.find(
        (group: any) => group.role === 'ADMIN' && group.statusUser === 'ACTIVE',
      ),
  );

  // applications?.data?.forEach((application: any) => {
  //   const groups: any[] = application.groups
  //     .map((group: any) => ({
  //       ...group.group,
  //       roleGroup: group.role,
  //       roleUser: group.roleUser,
  //       statusUser: group.statusUser,
  //       countUser: group.countUser,
  //       typeGroup: group.typeGroup,
  //     }))
  //     // Hides all custom groups with DENIED status
  //     .filter(
  //       (group: any) =>
  //         !(group.statusUser === 'DENIED' && group.typeGroup === 'CUSTOM') &&
  //     );
  //   formattedApplications.push({ ...application, groups });
  // });

  return formattedApplications;
};

// export const setApprovals = (
//   clientId: any,
//   status: any,
// ): Promise<void> => {
//   const query = buildQuery(SERVICE_REST + '/user/approvals', {
//     clientId,
//     status,
//   });
//   return PUT(query);
// };

// export const removeApprovals = (clientId: any): Promise<void> => {
//   const query = buildQuery(SERVICE_REST + '/user/approvals', { clientId });
//   return DELETE(query);
// };

export const fetchAppByLicense = async (client_id: string): Promise<IMessage<GetAppInfoDTO>> => {
  const accessToken = await getAccessToken();

  return await request<GetAppInfoDTO>('GET', BACKEND_URL + '/api/v1/application/' + client_id, {
    Authorization: 'Bearer ' + accessToken,
  });
};

export const refillBalance = async (license: string, client_id: string): Promise<IMessage<any>> => {
  const accessToken = await getAccessToken();

  const dto: CodeToAppDTO = {
    client_id,
    guid: license,
  };

  return await request<any>(
    'POST',
    BACKEND_URL + '/api/v1/application/license',
    {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
    JSON.stringify(dto),
  );
};
