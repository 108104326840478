import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'server/modules/general/dto/get-app-settings.dto';

export type TAppSettingsSlice = {
  products: Product[];
};

const initialState: TAppSettingsSlice = {
  products: [{ id: 0, product_name: '', product_code: '', allowed_license_types: [] }],
};

const appSettingsSlice = createSlice({
  name: 'appSettingsSlice',
  initialState,
  reducers: {
    saveProducts(state, action: PayloadAction<TAppSettingsSlice>) {
      state.products = action.payload.products.sort((a, b) => a.id - b.id);
    },
  },
});

export const { saveProducts } = appSettingsSlice.actions;
export default appSettingsSlice.reducer;
