import { IMessage, ResponseError } from '../interface';

export async function request<T>(
  method: 'GET' | 'POST' | 'PUT' | 'OPTIONS' | 'PATCH' | 'DELETE',
  url: string,
  headers?: HeadersInit,
  body?: BodyInit,
): Promise<IMessage<T>> {
  try {
    const response = await fetch(url, {
      method: method,
      headers,
      body,
    });

    if (!response.ok) throw new ResponseError(response.status, response.statusText);
    let json = await response.json();
    return {
      data: json.data as T,
    };
  } catch (e) {
    return Promise.reject(e);
  }
}
