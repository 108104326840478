export const downloadCsv = (data: string[]) => {
  const content = 'data:text/csv;charset=utf-8,' + data.join(',');
  const encodedUri = encodeURI(content);

  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'codes.csv');
  document.body.appendChild(link);
  link.click();
};
