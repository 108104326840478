import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import Tooltip from './Tooltip';
import { TooltipProps } from '@material-ui/core/Tooltip';
import { useStyles } from './OverflowTooltip.style';

const OverflowTooltip: React.FC<TooltipProps> = (props) => {
  const [overflowed, setOverflowed] = useState(false);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (content && content.current && content.current.scrollWidth > content.current.clientWidth) {
      setOverflowed(true);
    }
  }, []);

  const styles = useStyles();

  const noWrapChildren = React.cloneElement(props.children, {
    ref: content,
    className: clsx(props.children.props.className, styles.noWrap),
  });

  return (
    <Tooltip {...props} interactive disableHoverListener={!overflowed}>
      {noWrapChildren}
    </Tooltip>
  );
};

export default OverflowTooltip;
