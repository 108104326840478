//routes
export const CODE_GENERATE = '/admin/code';
export const CODE_CHECK = '/admin/code_check';
export const LICENSE_CHECK = '/admin/license_check';
export const APPLICATIONS = '/user/applications';

//main
export const DASHBOARD_URL = window?.env?.DASHBOARD_URL || 'https://lic.trusted.ru';
export const BACKEND_URL = window?.env?.BACKEND_URL || 'http://localhost:3003';

//auth
export const TRUSTED_URL = window?.env?.TRUSTED_URL || 'https://id.kloud.one';
export const AUTH_SCHEME = window?.env?.AUTH_SCHEME || 'new';
export const CLIENT_ID = window?.env?.CLIENT_ID || 'wMxX9BX8hvby3MePQiiGv';
export const CLIENT_SECRET =
  window?.env?.CLIENT_SECRET ||
  'vfr1M5uxj0KmEX642FpR2hhQwcxHiUQ8GRAdnjwpIygVABkXlC6ENyaIif74Sw3ULp1u2vUYRbO93iFOL1QECg';
export const ACCESS_TOKEN_TTL = parseInt(window?.env?.ACCESS_TOKEN_TTL || '1800000', 10); //30min
export const REFRESH_TOKEN_TTL = parseInt(window?.env?.REFRESH_TOKEN_TTL || '86400000 ', 10); //24h
