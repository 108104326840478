import { LinearProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTokensByCode } from '../requests/oidc';
import { saveUserProfile, saveUserRole } from '../redux/userSlice';
import {
  decodeJWT,
  getUserRoleInApp,
  isTokenValid,
  setTokenCookies,
} from '../service/newTrustedAuth';

type TCodeProps = unknown;
const mapStateToProps = () => ({});

const CodeComponent: React.FC<TCodeProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = async () => {
      try {
        const { accessToken, idToken, refreshToken, expiresIn } = await getTokensByCode();
        setTokenCookies(accessToken, refreshToken, expiresIn);
        if (await isTokenValid(idToken)) {
          const decodedJWT = decodeJWT(idToken);
          if (decodedJWT) {
            dispatch(saveUserProfile(decodedJWT));
            const role = await getUserRoleInApp(decodedJWT.sub || '');
            dispatch(saveUserRole(role));
          }
          history.push('/');
        } else {
          history.push('/login');
        }
      } catch (e) {
        console.log('auth error: ' + e);
        history.push('/login');
      }
    };

    auth();
  }, [dispatch, history]);

  return <LinearProgress />;
};

export const AuthByNewTrusted = connect(mapStateToProps)(CodeComponent);
