import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useCheckKeyStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    form: {
      alignItems: 'center',
      padding: 50,
    },
    text: {
      paddingTop: 50,
      color: theme.palette.secondary.main,
      fontSize: 30,
    },
    input: {
      width: 610,
      paddingBottom: 30,
      color: theme.palette.primary.main,
    },
    button: {
      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.primary.main,
      height: 36,
      width: 228,
    },
    responseOutput: {
      width: 610,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.main,
    },
    label: {
      fontSize: 28,
    },
    data: {
      fontSize: 18,
      height: 21,
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.dark,
    },
  }),
);
