import { Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import styles from './Login.module.css';
import { RootState } from '../redux/rootReducer';
import { TUserSlice } from '../redux/userSlice';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { handleLoginClick } from '../helpers';

const mapStateToProps = (state: RootState) => ({
  userRole: state.user.userRole,
});

type TLoginProps = Record<string, { userRole: TUserSlice['userRole'] }>;

const LoginComponent: React.FC<TLoginProps> = ({ userRole }) => {
  const history = useHistory();

  useEffect(() => {
    if (userRole) history.push('/');
  }, [userRole, history]);


  return (
    <Box className={styles.box}>
      <Button variant="contained" onClick={handleLoginClick}>
        Войди
      </Button>
    </Box>
  );
};

export const Login = connect(mapStateToProps)(LoginComponent);
