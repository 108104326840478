import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { useCheckKeyStyles } from './CheckKey.styles';
import { getActivationCodeInfo } from '../requests/license';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { errorToMessage } from '../utils/errorUtil';
import { convertCodeInfo, GetCodeInfoDTOConverted } from './../service/licenseUtils';
import { convertLicenseType } from '../helpers';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CheckKey: React.FC<{}> = () => {
  const styles = useCheckKeyStyles();
  const [toast, setToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [activationKey, setActivationKey] = useState<string>('');
  const [response, setResponse] = useState<Partial<GetCodeInfoDTOConverted>>();

  const handleActivationKey = async () => {
    setResponse({});
    try {
      const response = await getActivationCodeInfo(activationKey);
      setResponse(convertCodeInfo(response?.data));
    } catch (e) {
      if (e instanceof Error) {
        const message = errorToMessage(e);
        setToastMessage(message);
        setToast(true);
      }
    }
  };

  const handleClose = () => {
    setToast(false);
  };

  return (
    <>
      <Container className={styles.root}>
        <Typography className={styles.text}>Проверка ключа активации</Typography>
        <FormControl component="fieldset" className={styles.form}>
          <TextField
            required
            id="activationKey"
            label="Введите ключ"
            placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
            className={styles.input}
            size="medium"
            value={activationKey}
            onChange={(e) =>
              typeof e?.target?.value === 'string' && setActivationKey(e.target.value)
            }
          />
          <Button
            variant="contained"
            color="primary"
            disableRipple
            disableElevation
            disableFocusRipple
            className={styles.button}
            endIcon={<SendIcon />}
            onClick={handleActivationKey}
          >
            Проверить
          </Button>
        </FormControl>
        <div className={styles.responseOutput}>
          <Typography className={styles.label}>Продукт</Typography>
          <Typography className={styles.data}>{response?.product_name}</Typography>
          <Typography className={styles.label}>Статус ключа</Typography>
          <Typography className={styles.data}>{response?.status}</Typography>
          {response?.status === 'Активирован' && (
            <>
              <Typography className={styles.label}>Дата активации</Typography>
              <Typography className={styles.data}>{response?.activated}</Typography>
            </>
          )}
          <Typography className={styles.label}>Тип лицензии</Typography>
          <Typography className={styles.data}>
            {convertLicenseType(response?.license_type)}
          </Typography>
          {response?.license_type &&
            (response?.license_type === 'TRIAL' ? (
              <>
                <Typography className={styles.label}>Почта</Typography>
                <Typography className={styles.data}>{response?.email}</Typography>
                <Typography className={styles.label}>Код лицензии</Typography>
                <Typography className={styles.data}>{response?.license_guid}</Typography>
              </>
            ) : (
              <>
                <Typography className={styles.label}>Тип счета</Typography>
                <Typography className={styles.data}>{response?.account_type}</Typography>
                <Typography className={styles.label}>Длительность/количество лицензий</Typography>
                <Typography className={styles.data}>{response?.license_param}</Typography>
                <Typography className={styles.label}>Владелец</Typography>
                <Typography className={styles.data}>{response?.owner || '-'}</Typography>
                <Typography className={styles.label}>Остаток счета</Typography>
                <Typography className={styles.data}>{response?.balance}</Typography>
              </>
            ))}
        </div>
        <Snackbar open={toast} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {toastMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};
