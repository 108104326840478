import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { useGenerateKeyStyles } from './GenerateKey.styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Box from '@material-ui/core/Box';
import { downloadCsv } from '../Utils';
import clsx from 'clsx';
import { CLIENT_ID } from '../constants';
import { createActivationCode, createLicense } from '../requests/license';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { LicenseTypes } from 'server/modules/license/license.interface';
import { AccountType } from 'server/modules/code/code.interfaces';
import { RootState } from '../redux/rootReducer';
import { TAppSettingsSlice } from 'src/redux/appSettingsSlice';
import { connect } from 'react-redux';
import { Product } from 'server/modules/general/dto/get-app-settings.dto';
import { convertLicenseType, innValidationRegExp  } from '../helpers';

const mapStateToProps = (state: RootState) => ({
  products: state.appSettings.products,
});

interface IApplicationProps {
  products: TAppSettingsSlice['products'];
}

const GenerateKeyComponent: React.FC<IApplicationProps> = ({ products }) => {
  const styles = useGenerateKeyStyles();
  const [choosedProduct, setChoosedProduct] = useState<Product>(products[0] || []);
  const [accountType, setAccountType] = useState<keyof typeof AccountType>('subscription');
  const [choosedlicenseType, setLicenseType] = useState<keyof typeof LicenseTypes>('DAILY');
  const [numberOfLicenses, setNumberOfLicenses] = useState<'100' | '500' | '1000'>('100');
  const [numberOfKeys, setNumberOfKeys] = useState<number>(1);
  const [toast, setToast] = useState<boolean>(false);

  const [toastMessage, setToastMessage] = useState<string>('');
  const [codes, setCodes] = useState<string[]>([]);

  const [accountNumber, setAccountNumber] = useState<string>('');
  const [endUserName, setEndUserName] = useState<string>();
  const [partnerINN, setPartnerINN] = useState<string>();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!accountNumber) setLicenseType('DAILY');
  }, [accountNumber]);

  const handleGenerateClick = async () => {
    if (error) return;
    const params = {
      product_code: choosedProduct.product_code,
      client_id: CLIENT_ID,
      license_param: accountType === 'partner' ? numberOfLicenses : undefined,
      number_of_keys: numberOfKeys,
      license_type: choosedlicenseType,
      account_number: accountNumber,
      end_user_name: endUserName,
      partner_inn: partnerINN
    };
    try {
      switch (accountType) {
        case 'partner':
          setCodes(await createActivationCode({ ...params, account_type: accountType }));
          break;
        case 'subscription':
          setCodes(await createLicense(params as any));
          break;
        default:
          break;
      }
    } catch (e) {
      if (e instanceof Error) {
        setToastMessage(e.message);
        setToast(true);
      }
    }
  };

  const closeSnackbar = () => {
    setToast(false);
  };

  const handleCopyClick = () => {
    const textarea = document.querySelector('textarea');
    textarea?.select();
    document.execCommand('copy');
  };

  const handleSaveClick = () => {
    codes && downloadCsv(codes);
  };

  const handleChangeINN = (value: string) => {
    setPartnerINN(value);
    validateINN(value);
  };
   
  const validateINN = (value: string) => {
    if (value && !innValidationRegExp.test(value)) {
      setError(true);
    } else {
      setError(false);
    }
  };
  

  const LicenseParams: React.FC = () => {
    switch (accountType) {
      case 'partner':
        return (
          <>
            <Typography className={styles.text}>Количество лицензий</Typography>
            <Select
              className={clsx(styles.select, styles.underline)}
              value={numberOfLicenses}
              MenuProps={{ className: styles.menuPadding }}
              onChange={(e) =>
                (e?.target?.value === '100' ||
                  e?.target?.value === '500' ||
                  e?.target?.value === '1000') &&
                setNumberOfLicenses(e.target.value)
              }
            >
              <MenuItem className={styles.menu} value={'100'}>
                100 лицензий
              </MenuItem>
              <MenuItem className={styles.menu} value={'500'}>
                500 лицензий
              </MenuItem>
              <MenuItem className={styles.menu} value={'1000'}>
                1000 лицензий
              </MenuItem>
            </Select>
          </>
        );
      case 'subscription':
        return <></>;
    }
  };

  return (
    <>
      <div className={styles.body}>
        <Typography className={styles.text} style={{ justifyContent: 'center' }}>
          Генерация ключей
        </Typography>
        <Typography className={styles.text}>Продукт</Typography>
        <Select
          className={clsx(styles.select, styles.underline)}
          value={choosedProduct.product_name}
          MenuProps={{ className: styles.menuPadding }}
          onChange={(e) => {
            if (typeof e?.target?.value === 'string') {
              let choosedProduct = products.find((p) => p.product_name === e?.target?.value);
              setChoosedProduct(choosedProduct || products[0]);
              setLicenseType(choosedProduct?.allowed_license_types[0] || 'ANNUAL');
            }
          }}
        >
          {products?.map((product) => (
            <MenuItem className={styles.menu} key={product.id} value={product.product_name}>
              {product.product_name}
            </MenuItem>
          ))}
        </Select>
        {!accountNumber && (
          <>
            <Typography className={styles.text}>Тип лицензии</Typography>
            <Select
              className={clsx(styles.select, styles.underline)}
              value={choosedlicenseType}
              MenuProps={{ className: styles.menuPadding }}
              onChange={(e) =>
                setLicenseType(e.target.value as any)
              }
            >
              {choosedProduct.allowed_license_types.map((licenseType) => (
                <MenuItem key={licenseType} value={licenseType}>
                  {convertLicenseType(licenseType)}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <LicenseParams />
        <Typography className={styles.text}>Количество предоставляемых ключей</Typography>
        <TextField
          type="number"
          className={styles.textField}
          InputProps={{ inputProps: { min: 1 }, className: styles.underline }}
          value={numberOfKeys}
          onChange={(e) => e?.target?.value && setNumberOfKeys(parseInt(e.target.value, 10))}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography className={styles.text}>Наименование конечного пользователя</Typography>
        <TextField
          className={styles.textField}
          InputProps={{ className: styles.underline }}
          value={endUserName}
          onChange={(e) => setEndUserName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography className={styles.text}>ИНН партнера</Typography>
        <TextField
          className={styles.textField}
          InputProps={{ className: styles.underline }}
          value={partnerINN}
          onChange={(e) => handleChangeINN(e.target.value)}
          error={error}
          helperText={error ? 'ИНН должен состоять из 10 цифр.' : ''}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {accountType === 'partner' && (
          <>
            <Typography className={styles.text}>Номер счёта (опционально)</Typography>
            <TextField
              className={styles.textField}
              InputProps={{ inputProps: { min: 1 }, className: styles.underline }}
              value={accountNumber}
              onChange={(e) => e?.target?.value !== undefined && setAccountNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        )}
        <Box className={styles.buttonBox}>
          <Button
            variant="contained"
            disableFocusRipple
            disableElevation
            disableRipple
            className={styles.sendButton}
            onClick={handleGenerateClick}
            endIcon={<SendIcon />}
          >
            Сгенерировать
          </Button>
        </Box>
        <Button className={styles.button} onClick={handleCopyClick}>
          Скопировать
        </Button>
        <Button className={styles.button} onClick={handleSaveClick}>
          Скачать CSV
        </Button>
        <textarea readOnly className={styles.textarea} value={codes}></textarea>
        <Snackbar open={toast} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error">
            {toastMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export const GenerateKey = connect(mapStateToProps)(GenerateKeyComponent);
