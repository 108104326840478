import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TUserProfile = {
  authorities: any[];
  displayName: string;
  email: string;
  entityId: number;
  familyName: string;
  givenName: string;
  id: string;
  login: string;
  preferredName: string;
  properties: { type: string; value: string }[];
  username: string;
  phone: string;
};

export type AppScope = 'application' | 'provider' | 'userprofile' | 'userprofile_edit' | 'group';
export type ApprovalStatus = 'APPROVED' | 'DENIED' | 'NEW' | 'REQUEST';
export enum ApplicationTypes {
  API,
  MIKROTIK,
  EVISION,
  BITRIX,
  CRYPTOARM,
}
export type Approvals = {
  [scope in AppScope]: ApprovalStatus;
};

export type GroupType = 'DEFAULT' | 'CUSTOM';

export type UserRole = 'ADMIN' | 'USER' | 'OWNER';
export type UserStatus = 'ACTIVE' | 'NEW' | 'REQUEST' | 'DENIED' | 'BLOCKED' | null;
export enum Roles {
  USER = 'USER',
  EDITOR = 'EDITOR',
}

export type Group = {
  groupId: string;
  entityId: number;
  created: string;
  enabled: boolean;
  name: string;
  description?: string;
  roleGroup: UserRole;
  roleUser: UserRole;
  statusUser: UserStatus;
  countUser: number;
  typeGroup: GroupType;
};

export type TApplication = {
  appName: string;
  clientId: string;
  description?: string;
  entityId: number;
  appImgUrl: string;
  appScopes: AppScope[];
  approvals: Approvals;
  hostUri: string;
  type: ApplicationTypes;
  ownerId: number;
  lastOperation?: string;
  groups: Group[];
};

export type TUserSlice = {
  userProfile?: Partial<TUserProfile>;
  userAvatar: string;
  userRole?: Roles | 'manager' | 'user';
  applications: { isFetching: boolean; data: TApplication[] };
};

const initialState: TUserSlice = {
  userProfile: undefined,
  userAvatar: '',
  userRole: undefined,
  applications: { isFetching: false, data: [] },
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    saveUserProfile(state, action: PayloadAction<Partial<TUserProfile>>) {
      state.userProfile = action.payload;
    },
    saveUserAvatar(state, action: PayloadAction<string>) {
      state.userAvatar = action.payload;
    },
    saveUserRole(state, action: PayloadAction<TUserSlice['userRole']>) {
      state.userRole = action.payload;
    },
    saveUserApps(state, action: PayloadAction<TUserSlice['applications']>) {
      state.applications = action.payload;
    },
  },
});

export const { saveUserProfile, saveUserAvatar, saveUserRole, saveUserApps } = userSlice.actions;
export default userSlice.reducer;
