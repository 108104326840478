import React from 'react'
import OriginalTooltip, {
  TooltipProps,
} from '@material-ui/core/Tooltip/Tooltip'

const Tooltip: React.FC<TooltipProps> = props => (
  <OriginalTooltip
    {...props}
    PopperProps={{
      modifiers: {
        flip: { enabled: false },
        offset: { enabled: true, offset: '0px, -10px' },
      },
    }}
  >
    {props.children}
  </OriginalTooltip>
)

export default Tooltip
