import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useMainStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 8,
    },
    text: {
      padding: 8,
      color: theme.palette.secondary.main,
      fontSize: '1rem',
    },
  }),
);
