import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useApplicationStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionIcon: {
      fontSize: '28px',
    },
    titleContainer: {
      width: 'calc(100% - 70px)',
      justifyContent: 'space-between',
    },
    boxStyles: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '610px',
    },
    licenseInput: {
      paddingBottom: '20px',
      width: '400px',
    },
    balanceBlock: {
      padding: theme.spacing(1),
    },
    label: {
      fontSize: 18,
      color: theme.palette.secondary.main,
      paddingRight: '10px',
      textAlign: 'center',
    },
    data: {
      fontSize: 18,
      height: 21,
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
    buttonBlock: {
      padding: theme.spacing(1),
    },
    button: {
      height: 36,
      width: 228,
    },
  }),
);
