import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Cookies from 'universal-cookie';
import { connect, useDispatch } from 'react-redux';
import { saveUserRole, TUserSlice } from '../../redux/userSlice';
import { RootState } from '../../redux/rootReducer';
import { saveProfileNewTrusted } from '../../service/newTrustedAuth';
import { isManager } from '../../helpers';

const cookies = new Cookies();

type NewManagerRouteProps = RouteProps & { userRole: TUserSlice['userRole'] };

const mapStateToProps = (state: RootState) => ({
  userRole: state.user.userRole,
});

const NewManagerRoute: React.FC<NewManagerRouteProps> = ({ children, userRole, path, ...rest }) => {
  const [wait, setWait] = React.useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = cookies.get('accessToken');

    if (token && userRole) setWait(false);
  }, [userRole]);

  useEffect(() => {
    setWait(true);
  }, [path]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = cookies.get('accessToken');

        if (!token) {
          await saveProfileNewTrusted(dispatch);
        }
      } catch (e) {
        dispatch(saveUserRole(undefined));
        setWait(false);
      }
      setTimeout(() => setWait(false), 1000);
    };

    getToken();
  });

  return wait ? (
    <LinearProgress style={{ width: '100%' }} />
  ) : (
    <Route {...rest} render={() => (isManager(userRole) ? children : <Redirect to="/main" />)} />
  );
};

export default connect(mapStateToProps)(NewManagerRoute);
